import { readFileSync } from 'fs'

export const DEFLATE_DICTIONARY =
    '{"zip":"DEF","alg":"ES256","kid":{"iss":"https://myvaccinerecord.cdph.ca.gov/creds","nbf","vc":{"type":["https://smarthealth.cards#health-card","https://smarthealth.cards#immunization","https://smarthealth.cards#covid19"],"credentialSubject":{"fhirVersion":"4.0.1","fhirBundle":{"resourceType":"Bundle","type":"collection","entry":[{"fullUrl":"resource:0","resource":{"resourceType":"Patient","name":[{"family":"","given":[""]}],"birthDate":""}},{"fullUrl":"resource:1","resource":{"resourceType":"Immunization","status":"completed","vaccineCode":{"coding":[{"system":"http://hl7.org/fhir/sid/cvx","code":""}]},"patient":{"reference":"resource:0"},"occurrenceDateTime":"2021","lotNumber":"","performer":[{"actor":{"display":""}}]}},{"fullUrl":"resource:2","resource":{"resourceType":"Immunization","status":"completed","vaccineCode":{"coding":[{"system":"http://hl7.org/fhir/sid/cvx","code":""}]},"patient":{"reference":"resource:0"},"occurrenceDateTime":,"lotNumber":,"performer":[{"actor":{"display":""}}]}}]}}}}'

// This is a short representation of the data from the CDC
// CVX database.
export const shortVaccineCodes = {
    '207': 'Moderna',
    '208': 'Pfizer',
    '210': 'AstraZeneca',
    '211': 'Novavax',
    '212': 'J&J',
    '213': 'N/A',
    '500': 'N/A',
    '501': 'QAZCOVID',
    '502': 'COVAXIN',
    '503': 'COVIVAC',
    '504': 'Sputnik',
    '505': 'Sputnik',
    '506': 'CanSino',
    '507': 'Zhifei',
    '508': 'Jiangsu',
    '509': 'EpiVacCorona',
    '510': 'Sinopharm',
    '511': 'Sinovac',
}

const parser = new DOMParser()
const CVX = readFileSync(__dirname + '/CVX.xml', 'utf8')
// https://www2a.cdc.gov/vaccines/iis/iisstandards/XML2.asp?rpt=cvx
const xmlDoc = parser.parseFromString(CVX, 'text/xml')

export const cvxData = Array.from(xmlDoc.querySelectorAll('CVXInfo')).map((k) => ({
    description: k.querySelector('ShortDescription')?.textContent!.trim(),
    name: k.querySelector('FullVaccinename')?.textContent!.trim(),
    code: k.querySelector('CVXCode')?.textContent!.trim(),
    status: k.querySelector('Status')?.textContent!.trim(),
    updated: k.querySelector('LastUpdated')?.textContent!.trim(),
    notes: k.querySelector('Notes')?.textContent!.trim(),
}))

export const cvxMap = Object.fromEntries(cvxData.map((k) => [k.code, k]))

// https://myvaccinerecord.cdph.ca.gov/creds/.well-known/jwks.json
const CDPH_KEY = {
    kty: 'EC',
    kid: '7JvktUpf1_9NPwdM-70FJT3YdyTiSe2IvmVxxgDSRb0',
    use: 'sig',
    alg: 'ES256',
    crv: 'P-256',
    x: '3dQz5ZlbazChP3U7bdqShfF0fvSXLXD9WMa1kqqH6i4',
    y: 'FV4AsWjc7ZmfhSiHsw2gjnDMKNLwNqi2jMLmJpiKWtE',
}

export const jwks = {
    keys: [CDPH_KEY],
}
