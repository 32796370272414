import * as ReactDOM from 'react-dom'
import * as React from 'react'
import { renderSummaryCard } from './shared'
import { QRIcon, WalletIcon, FacebookIcon, TwitterIcon } from './icons'
import './styles.less'

function Layout({ children }: { children: React.ReactNode }) {
    const authors = [
        <a href="https://twitter.com/antimatter15">Kevin Kwok</a>,
        <a href="https://twitter.com/biject">Guillermo Webster</a>,
    ].sort((a, b) => Math.random() - 0.5)

    return (
        <React.Fragment>
            <div className="content">
                <div>
                    <div className="header">
                        <h1 className="thing">CA Vaccine Record</h1>
                        <h1>&rarr;</h1>
                        <h1 className="thing">Apple Wallet</h1>
                    </div>
                    {children}
                </div>
            </div>
            <ShareButtons />

            <div className="footer">
                <div className="footer-inner">
                    <p>
                        This tool operates entirely on your device— your health information is never
                        transmitted to any server.{' '}
                        {/*<a href="https://github.com/antimatter15/covid-wallet">Learn more</a>.*/}
                    </p>
                    <p>
                        Details from the SMART Health QR Code are cryptographically verified by the
                        California Department of Public Health to prevent forgery.
                    </p>
                    <p>
                        This tool is not affiliated with Apple nor the California Department of
                        Public Health nor the US Centers for Disease Control.
                    </p>
                    <div>
                        <p>
                            Created by {authors[0]} and {authors[1]}, and unwittingly launched by
                            another <a href="http://twitter.com/kevinakwok">Kevin Kwok</a>.
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function ShareButtons() {
    // We're not using the official sharing snippets because they inject third party Javascript
    return (
        <div className="share-buttons">
            <a
                target="_blank"
                rel="noopener"
                href={'//www.facebook.com/sharer.php?u=https://covidwallet.app/'}
                className="social-sharing__link"
                title="Share on Facebook"
            >
                <FacebookIcon />
                <span aria-hidden="true">Share</span>
                <span className="visually-hidden">Share on Facebook</span>
            </a>{' '}
            <a
                target="_blank"
                href={`//twitter.com/share?text=Check out this app for adding your COVID-19 Vaccination Record to Apple Wallet&url=https://covidwallet.app/`}
                className="social-sharing__link"
                title="Tweet on Twitter"
                rel="noopener"
            >
                <TwitterIcon />
                <span aria-hidden="true">Tweet</span>
                <span className="visually-hidden">Tweet on Twitter</span>
            </a>
        </div>
    )
}

function Loading() {
    return (
        <Layout>
            <div style={{ textAlign: 'center' }}>
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </Layout>
    )
}

async function main() {
    if (location.hash) {
        await renderSummaryCard()
        return
    }
    let passData
    let startTime: number

    setTimeout(() => {
        import('./generate')
    }, 1000)

    while (true) {
        const ref = React.createRef<HTMLInputElement>()
        const fileData = await new Promise((resolve) => {
            ReactDOM.render(
                <Layout>
                    <ol>
                        <li>
                            Take a screenshot of your{' '}
                            <a
                                target="_blank"
                                rel="noopener"
                                href="https://myvaccinerecord.cdph.ca.gov/"
                            >
                                California Digital COVID-19 Vaccine Record SMART QR Code ↗
                            </a>
                        </li>
                        <li>Select your screenshot below to create an Apple Wallet Pass</li>
                    </ol>

                    {navigator.userAgent.match('FxiOS') ? (
                        <div
                            className="appleWallet"
                            style={{ background: 'gray', textAlign: 'center' }}
                            onClick={(e) => {
                                if (ref.current) ref.current.click()
                            }}
                        >
                            <div className="appleWalletInner">
                                <div>
                                    Unfortunately Firefox on iOS is unsupported, please try with
                                    Safari or Chrome instead.
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="appleWallet"
                            style={{ background: '#4b5286' }}
                            onClick={(e) => {
                                if (ref.current) ref.current.click()
                            }}
                        >
                            <div className="appleWalletInner">
                                <div
                                    style={{
                                        background: 'white',
                                        marginRight: 10,
                                        display: 'flex',
                                    }}
                                >
                                    <QRIcon />
                                </div>
                                <div>Select Vaccine Record QR Code</div>
                            </div>
                        </div>
                    )}

                    <input
                        className="hidden"
                        ref={ref}
                        type="file"
                        accept="image/png,image/jpeg"
                        onChange={(e) => {
                            resolve(e.target.files![0])
                        }}
                    />
                </Layout>,
                document.getElementById('root')
            )
        })

        ReactDOM.render(<Loading />, document.getElementById('root'))

        startTime = Date.now()
        const image: HTMLImageElement = await new Promise((resolve) => {
            const img = new Image()
            img.src = URL.createObjectURL(fileData)
            img.onload = () => resolve(img)
        })
        const { processImage } = await import('./generate')
        const data = await processImage(image)
        if (!data) {
            continue
        }
        passData = data
        break
    }

    const { generatePass } = await import('./generate')
    let locationData: null | {
        latitude: number
        longitude: number
    } = null
    while (true) {
        const url = await generatePass(passData, locationData)

        var isSafariDesktop =
            /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
            !/iphone|ipod|ipad/i.test(navigator.userAgent)
        const isIOS =
            [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
            ].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

        const timeElapsed = Date.now() - startTime
        await delay(Math.max(0, 1000 - timeElapsed))

        await new Promise((resolve) => {
            ReactDOM.render(
                <Layout>
                    <a
                        href={url}
                        className="add-pass"
                        download={isSafariDesktop ? null : 'covid.pkpass'}
                        target="_system"
                    >
                        <div className="appleWallet fadein">
                            <div className="appleWalletInner">
                                <div>
                                    <WalletIcon />
                                </div>
                                <div>Add to Apple Wallet</div>
                            </div>
                        </div>
                    </a>

                    <label className="location-trigger">
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                startTime = Date.now()
                                ReactDOM.render(<Loading />, document.getElementById('root'))

                                if (e.target.checked) {
                                    navigator.geolocation.getCurrentPosition(
                                        (position) => {
                                            console.log(position.coords)
                                            locationData = position.coords

                                            resolve(true)
                                        },
                                        (error) => {
                                            alert('Unable to get device location')
                                            resolve(true)
                                        }
                                    )
                                } else {
                                    locationData = null
                                    startTime = Date.now()
                                    resolve(true)
                                }
                            }}
                            checked={locationData !== null}
                        />{' '}
                        Automatically show vaccine record on lock screen when around current
                        location
                    </label>
                </Layout>,
                document.getElementById('root')
            )
        })
    }
}

async function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

main().catch((err) => alert(err))
