export const promises = {}

export function createReadStream() {}

export function open() {}
export function close() {}
export function read() {}
export function write() {}
export function fstat() {}
export default {}
