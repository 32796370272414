import * as ReactDOM from 'react-dom'
import * as React from 'react'
import * as pako from 'pako'
import { shortVaccineCodes, DEFLATE_DICTIONARY, cvxMap } from './data'

// O(n^2) arbitrary base conversion utility function.
export function baseConvert(digits: number[], srcb: number, destb: number) {
    let start = 0,
        result = []
    while (true) {
        let carry = 0,
            done = true
        for (let i = start; i < digits.length; i++) {
            let p = srcb * carry + digits[i]
            digits[i] = Math.floor(p / destb)
            carry = p % destb
            if (done) {
                if (!digits[i]) start = i
                else done = false
            }
        }
        result.unshift(carry)
        if (done) return result
    }
}

type VaccineEntry = {
    resource: {
        vaccineCode: {
            coding: {
                code: keyof typeof shortVaccineCodes
            }[]
        }
        performer: {
            actor: {
                display: string
            }
        }[]
        occurrenceDateTime: string
        lotNumber: string

        name: {
            given: string[]
            family: string
        }[]

        birthDate: string
    }
}

export function showDoseDetails(data: VaccineEntry) {
    return (
        'Administered on: ' +
        data.resource.occurrenceDateTime +
        '\n' +
        'Administrated by: ' +
        (data.resource.performer?.map((k) => k.actor.display).join(', ') || 'N/A') +
        '\n' +
        'Vaccine Serial Number: ' +
        (data.resource.lotNumber || 'N/A') +
        '\n\n' +
        'Vaccine HL7 Code: ' +
        data.resource.vaccineCode.coding[0].code +
        '\n' +
        'Vaccine Description: ' +
        (cvxMap[data.resource.vaccineCode.coding[0].code]?.description || 'N/A') +
        '\n' +
        'Vaccine Status: ' +
        (cvxMap[data.resource.vaccineCode.coding[0].code]?.status || 'N/A') +
        '\n' +
        'Vaccine Notes: ' +
        (cvxMap[data.resource.vaccineCode.coding[0].code]?.notes || 'N/A')
    )
}

export function showDoseSummary(data: VaccineEntry) {
    return (
        (shortVaccineCodes[data.resource.vaccineCode.coding[0].code] || '') +
        ' ' +
        (data.resource.lotNumber || '') +
        ' ' +
        (data.resource.occurrenceDateTime || '')
    )
}

export async function renderSummaryCard() {
    const data = pako.inflateRaw(
        Buffer.from(
            baseConvert(
                location.hash
                    .slice(1)
                    .split('')
                    .map((k) => parseInt(k)),
                10,
                256
            )
        ),
        {
            dictionary: Buffer.from(DEFLATE_DICTIONARY, 'utf8'),
        } as any
    )
    const json = JSON.parse(Buffer.from(data).toString('utf8'))
    const entries: VaccineEntry[] = json.vc.credentialSubject.fhirBundle.entry

    ReactDOM.render(
        <div>
            <a href="">&larr; Add COVID Vaccination Record to Apple Wallet</a>
            <h2>
                {entries[0].resource.name[0].given.join(' ') +
                    ' ' +
                    entries[0].resource.name[0].family}
            </h2>
            <h4>Issuer: {json.iss}</h4>
            <p>Birth Date: {entries[0].resource.birthDate}</p>
            <div style={{ display: 'flex' }}>
                {entries[1] && (
                    <fieldset>
                        <legend>Dose 1</legend>
                        {showDoseSummary(entries[1])}
                        {entries[1].resource.performer && (
                            <p>
                                {entries[1].resource.performer
                                    .map((k) => k.actor.display)
                                    .join(', ')}
                            </p>
                        )}
                    </fieldset>
                )}
                {entries[2] && (
                    <fieldset>
                        <legend>Dose 2</legend>
                        {showDoseSummary(entries[2])}
                        {entries[2].resource.performer && (
                            <p>
                                {entries[2].resource.performer
                                    .map((k) => k.actor.display)
                                    .join(', ')}
                            </p>
                        )}
                    </fieldset>
                )}
                {entries[3] && (
                    <fieldset>
                        <legend>Dose 3</legend>
                        {showDoseSummary(entries[3])}
                        {entries[3].resource.performer && (
                            <p>
                                {entries[3].resource.performer
                                    .map((k) => k.actor.display)
                                    .join(', ')}
                            </p>
                        )}
                    </fieldset>
                )}
            </div>
            <details>
                <summary>Show Raw JSON</summary>
                {JSON.stringify(json)}
            </details>
        </div>,
        document.getElementById('root')
    )
}
